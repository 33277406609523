<template>
  <div ref="container">
    <span class="text-5xl leading-tight text-yellow-500 lg:text-6xl lg:leading-tight tabular-nums">{{ animatednumber }}{{ plus ? '+' : '' }}</span>
    <div class="font-normal text-white text-normal lg:text-lg lg:font-medium"
         v-html="description"
    />
  </div>
</template>

<script setup lang="ts">
import { animate, inView } from 'motion'
import { onMounted, ref } from 'vue'

interface numberCounter {
  number: number
  plus: boolean
  description: string
}

const props = defineProps<numberCounter>()
const animatednumber = ref(0)

const container = ref()

onMounted(() => {
  inView(
    container.value,
    () => {
      animate(progress => (animatednumber.value = Math.round(progress * props.number)), { duration: 2, easing: 'ease-out' })
    },
    { amount: 0.4 }
  )
})
</script>
